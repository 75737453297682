import {Button} from '@gs-ux-uitoolkit-react/button';
import {Icon} from '@gs-ux-uitoolkit-react/icon-font';
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_CHAT, GET_REQUEST_DETAILS} from "../../constants/GraphqlCallDefinitions";
import {ChatTypes, chatTypesToButtonNames, chatTypesToIdFields} from "../../constants/ChatTypes";
import {ButtonStatus} from "@gs-ux-uitoolkit-common/button/dist/button/button-props";
import {useEffect, useState} from "react";
import {showToast} from "../Notification/NotificationService";
import {openChat} from "../../services/utils/TeamsService";
import {reportToEUM} from "../../services/EUMService";

interface ChatButtonProps {
    chatType: ChatTypes;
}

export const ChatButton = ({chatType}: ChatButtonProps) => {
    const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('none');
    const ircRequestData = useQuery(GET_REQUEST_DETAILS);
    const [chatId, setChatId] = useState<String | undefined | null>(undefined);
    const [createChat, {
        loading,
        error
    }] = useMutation(CREATE_CHAT);

    const onClick = async () => {
        reportToEUM(`Clicked to create chat of type ${chatType} with chatId ${chatId}`, {chatType});
        if (!chatId) {
            setButtonStatus('loading');
            const createChatData = await createChat({variables: {chatType: chatType}})
            setChatId(createChatData?.data?.createChat?.chatId);
            if (!error && !loading) {
                setButtonStatus('none');
                openChat(createChatData?.data?.createChat?.chatId);
            }
        } else {
            openChat(chatId);
        }
    }

    useEffect(() => {
        if (ircRequestData?.data?.getIRCRequest) {
            setChatId(ircRequestData?.data?.getIRCRequest[chatTypesToIdFields[chatType]]);
        }
    }, [ircRequestData?.data?.getIRCRequest[chatTypesToIdFields[chatType]]])

    useEffect(() => {
        if (error) {
            setButtonStatus('error');
            showToast({
                status: 'error',
                message: `Unable to create chat. If the error continues, please reach out to Publication Support.`,
                autoDismiss: 15000,
            });
        }
    }, [loading, error]);

    const chatName = chatTypesToButtonNames[chatType];
    const chatMessage = chatId ? `Resume ${chatName} Chat` : `Create ${chatName} Chat`;

    return (
        <Button
            onClick={onClick}
            status={buttonStatus}
            actionType="contrast"
            emphasis="bold"
            style={{marginRight: '8px'}}
        >
            <Icon name="chat-bubble" type="outlined"/>
            {chatMessage}
        </Button>
    )
}